<template>
  <div class="container">
    <div class="top" id="bloc-0">
      <voice-header></voice-header>
    </div>
    
    <div class="main voice-cloning-page">
      <!-- Hero Section -->
      <div class="hero-section">        
        <div class="hero-content">
          <h1>{{ $t('voice_cloning.heroTitle') }}</h1>
          <p class="hero-subtitle">{{ $t('voice_cloning.heroSubtitle') }}</p>
          <div class="cta-buttons">
            <router-link :to="pricing_href" title="TikTok Voice Generator pricing" class="btn-primary">{{ $t('voice_cloning.viewPricing') }}</router-link>
          </div>
        </div>
      </div>

      <!-- Features Section -->
      <section class="features-section">
        <div class="feature-row">
          <div class="feature-text">
            <h2>{{ $t('voice_cloning.featureAI.title') }}</h2>
            <h3 class="feature-desc">{{ $t('voice_cloning.featureAI.desc') }}</h3>
            <div class="feature-points">
              <h3 v-for="(point, index) in $t('voice_cloning.featureAI.points')" :key="index" class="point-item">{{ point }}</h3>
            </div>
          </div>
          <div class="feature-image">
            <img src="/ssr/img/feature-ai.webp" loading="lazy" fetchpriority="low" alt="AI Voice Cloning feature" height="300px" width="350px" >
          </div>
        </div>
        
        <div class="feature-row reverse">
          <div class="feature-text">
            <h2>{{ $t('voice_cloning.featureProcess.title') }}</h2>
            <h3>{{ $t('voice_cloning.featureProcess.desc') }}</h3>
            <div class="feature-points">
              <h3 v-for="(point, index) in $t('voice_cloning.featureProcess.points')" :key="index" class="point-item">{{ point }}</h3>
            </div>
          </div>
          <div class="feature-image">
            <img src="/ssr/img/feature-process.webp" loading="lazy" fetchpriority="low" alt="AI Voice Cloning Process" height="300px" width="350px">
          </div>
        </div>

        <div class="feature-row">
          <div class="feature-text">
            <h2>{{ $t('voice_cloning.featureApps.title') }}</h2>
            <h3>{{ $t('voice_cloning.featureApps.desc') }}</h3>
            <div class="feature-points">
              <h3 v-for="(point, index) in $t('voice_cloning.featureApps.points')" :key="index" class="point-item">{{ point }}</h3>
            </div>
          </div>
          <div class="feature-image">
            <img src="/ssr/img/feature-applications.webp" loading="lazy" alt="AI Voice Cloning Applications" height="300px" width="350px">
          </div>
        </div>
        
        <div class="feature-row reverse">
          <div class="feature-text">
            <h2>{{ $t('voice_cloning.featureSecurity.title') }}</h2>
            <h3>{{ $t('voice_cloning.featureSecurity.desc') }}</h3>
            <div class="feature-points">
              <h3 v-for="(point, index) in $t('voice_cloning.featureSecurity.points')" :key="index" class="point-item">{{ point }}</h3>
            </div>
          </div>
          <div class="feature-image">
            <img src="/ssr/img/feature-security.webp" loading="lazy" fetchpriority="low" alt="AI Voice Cloning Security Features" height="300px" width="350px">
          </div>
        </div>
      </section>

      <!-- Integration Steps Section -->
      <section class="integration-steps-section">
        <h2>{{ $t('voice_cloning.howItWorks.title') }}</h2>
        <div class="steps-container">
          <div class="step-item" v-for="(step, index) in $t('voice_cloning.howItWorks.steps')" :key="index">
            <div class="step-number">{{ index + 1 }}</div>
            <h3>{{ step.title }}</h3>
            <p>{{ step.desc }}</p>
          </div>
        </div>
      </section>

      <!-- FAQ Section -->
      <section class="faq-section">
        <h2>{{ $t('voice_cloning.faqs.faq_title') }}</h2>
        <div class="faq-grid">
          <div 
            v-for="(faq, index) in faqs" 
            :key="index"
            class="faq-item"
            :class="{ 'active': faq.isOpen }"
          >
            <div class="faq-question" @click="toggleFaq(index)">
              <h3>{{ $t(`voice_cloning.faqs.quest${index + 1}`) }}</h3>
              <svg 
                class="arrow-icon" 
                viewBox="0 0 24 24" 
                width="24" 
                height="24"
                :class="{ 'rotated': faq.isOpen }"
              >
                <path fill="currentColor" d="M7 10l5 5 5-5H7z"/>
              </svg>
            </div>
            <div class="faq-answer" v-show="faq.isOpen">
              <p>{{ $t(`voice_cloning.faqs.answer${index + 1}`) }}</p>
            </div>
          </div>
        </div>
      </section>
    </div>

    <div class="footer">
      <voice-footer></voice-footer>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import VoiceHeader from '../components/VoiceHeader.vue'
import { reportError } from '../utils/errorReporter'
import { onLCP } from 'web-vitals/attribution';
import { reportWebVital } from '../utils/reportWebVital'

const VoiceFooter = defineAsyncComponent(() => import('../components/VoiceFooterIndex.vue'))

export default {
  name: 'VoiceCloning',
  components: {
    VoiceHeader,
    VoiceFooter
  },
  head() {
    return {
      title: this.$t('voice_cloning.headTitle'),
      keywords: this.$t('voice_cloning.headKeywords'),
      description: this.$t('voice_cloning.headDescription')
    }
  },
  metaInfo() {
    return {
      link: [
        { rel: 'alternate', hreflang: 'x-default', href: 'https://tiktokvoice.net/en/voice-cloning' },
        { rel: 'alternate', hreflang: 'en', href: 'https://tiktokvoice.net/en/voice-cloning' },
        { rel: 'alternate', hreflang: 'ja', href: 'https://tiktokvoice.net/ja/voice-cloning' },
        { rel: 'alternate', hreflang: 'zh', href: 'https://tiktokvoice.net/zh/voice-cloning' },
        { rel: 'alternate', hreflang: 'zh-tw', href: 'https://tiktokvoice.net/zh-tw/voice-cloning' },
        { rel: 'alternate', hreflang: 'ko', href: 'https://tiktokvoice.net/ko/voice-cloning' },
        { rel: 'alternate', hreflang: 'vi', href: 'https://tiktokvoice.net/vi/voice-cloning' },
        { rel: 'alternate', hreflang: 'th', href: 'https://tiktokvoice.net/th/voice-cloning' },
        { rel: 'alternate', hreflang: 'hi', href: 'https://tiktokvoice.net/hi/voice-cloning' },
        { rel: 'alternate', hreflang: 'fa', href: 'https://tiktokvoice.net/fa/voice-cloning' },
        { rel: 'alternate', hreflang: 'ru', href: 'https://tiktokvoice.net/ru/voice-cloning' },
        { rel: 'alternate', hreflang: 'de', href: 'https://tiktokvoice.net/de/voice-cloning' },
        { rel: 'alternate', hreflang: 'fr', href: 'https://tiktokvoice.net/fr/voice-cloning' },
        { rel: 'alternate', hreflang: 'ro', href: 'https://tiktokvoice.net/ro/voice-cloning' },
        { rel: 'alternate', hreflang: 'cs', href: 'https://tiktokvoice.net/cs/voice-cloning' },
        { rel: 'alternate', hreflang: 'es', href: 'https://tiktokvoice.net/es/voice-cloning' },
        { rel: 'alternate', hreflang: 'pt', href: 'https://tiktokvoice.net/pt/voice-cloning' },
        { rel: 'alternate', hreflang: 'bn', href: 'https://tiktokvoice.net/bn/voice-cloning' },
        { rel: 'alternate', hreflang: 'it', href: 'https://tiktokvoice.net/it/voice-cloning' },
        { rel: 'alternate', hreflang: 'ar', href: 'https://tiktokvoice.net/ar/voice-cloning' },
        { rel: 'alternate', hreflang: 'ur', href: 'https://tiktokvoice.net/ur/voice-cloning' },
        { rel: 'alternate', hreflang: 'ms', href: 'https://tiktokvoice.net/ms/voice-cloning' },
        { rel: 'alternate', hreflang: 'tr', href: 'https://tiktokvoice.net/tr/voice-cloning' },
        { rel: 'alternate', hreflang: 'pl', href: 'https://tiktokvoice.net/pl/voice-cloning' },
        { rel: 'alternate', hreflang: 'nl', href: 'https://tiktokvoice.net/nl/voice-cloning' },
        { rel: 'alternate', hreflang: 'uk', href: 'https://tiktokvoice.net/uk/voice-cloning' },
      ]
    }
  },
  data() {
    return {
      pricing_href: '/en/pricing',
      faqs: Array(10).fill().map(() => ({ isOpen: false }))
    }
  },
  methods: {
    toggleFaq(index) {
      this.faqs.forEach((faq, i) => {
        if (i === index) {
          this.$set(this.faqs[i], 'isOpen', !faq.isOpen)
        } else {
          this.$set(this.faqs[i], 'isOpen', false)
        }
      })
    }
  },
  mounted() {
    this.pricing_href = '/' + this.$store.state.lang + '/pricing'
    // 错误处理监听
    this.errorHandler = (event) => reportError(event.error, 'window.error')
    this.rejectionHandler = (event) => reportError(event.reason, 'unhandledrejection')
    window.addEventListener('error', this.errorHandler)
    window.addEventListener('unhandledrejection', this.rejectionHandler)
    // try {
    //   onLCP((metric) => reportWebVital(metric))
    // } catch (error) {
    //   reportError(error, 'Cloning page Failed to initialize INP monitoring')
    // }
  }
}
</script>

<style scoped>
@import url('/ssr/css/voice_cloning.css');

html, body { margin: 0; padding: 0; overflow-x: hidden; width: 100%; max-width: 100%; }
.container { width: 100%; max-width: 100%; min-height: 100vh; background: #ffffff; margin: 0; padding: 0; overflow-x: hidden; position: relative; box-sizing: border-box; }
.main.voice-cloning-page { width: 100%; max-width: 100%; margin: 0; padding: 0; overflow-x: hidden; box-sizing: border-box; }

.hero-section { position: relative; text-align: center; padding: 100px 0 80px; overflow: hidden; width: 100%; left: 0; right: 0; background: linear-gradient(180deg, rgba(255,255,255,0.95) 0%, rgba(255,255,255,0.98) 100%); }
.hero-content { position: relative; z-index: 2; width: 100%; margin: 0 auto; padding: 0 12%; }
.hero-section h1 { font-size: 3.5rem; font-weight: 700; margin-bottom: 20px; background: linear-gradient(45deg, #2196F3, #4CAF50); background-clip: text; -webkit-background-clip: text; -webkit-text-fill-color: transparent; }
.hero-subtitle { font-size: 1.5rem; color: #333; margin-bottom: 40px; }

.cta-buttons { display: flex; gap: 20px; justify-content: center; max-width: 100%; box-sizing: border-box; padding: 0 20px; }
.btn-primary, .btn-secondary { padding: 15px 40px; border-radius: 8px; font-weight: 600; text-decoration: none; transition: all 0.3s ease; min-width: 180px; text-align: center; white-space: nowrap; display: inline-flex; align-items: center; justify-content: center; }
.btn-primary { background: #0C74CA; color: white !important; }
.btn-primary:hover { background: #1976D2; transform: translateY(-2px); }
.btn-secondary { border: 2px solid #2196F3; color: #2196F3 !important; }
.btn-secondary:hover { background: rgba(33, 150, 243, 0.1); transform: translateY(-2px); }

@media (max-width: 768px) {
  .hero-section { padding: 80px 0 60px; }
  .hero-content { padding: 0 6%; }
  .hero-section h1 { font-size: 2.5rem; margin-bottom: 15px; }
  .hero-subtitle { font-size: 1.2rem; margin-bottom: 30px; padding: 0 20px; }
  .cta-buttons { flex-direction: column; align-items: stretch; gap: 15px; padding: 0 30px; }
  .btn-primary, .btn-secondary { width: 100%; min-width: unset; padding: 16px 20px; font-size: 1.3rem; }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .hero-section { padding: 90px 0 70px; }
  .hero-content { padding: 0 8%; }
  .hero-section h1 { font-size: 3rem; }
  .hero-subtitle { font-size: 1.3rem; }
  .cta-buttons { padding: 0 30px; }
  .btn-primary, .btn-secondary { min-width: 200px; padding: 15px 30px; }
}

</style>
